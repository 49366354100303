/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */




@import '../../node_modules/@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */

@import '../../node_modules/@ionic/angular/css/normalize.css';
@import '../../node_modules/@ionic/angular/css/structure.css';
@import '../../node_modules/@ionic/angular/css/typography.css';
@import '../../node_modules/@ionic/angular/css/display.css';


/* Optional CSS utils that can be commented out */
/*
@import '../../node_modules/@ionic/angular/css/padding.css';
@import '../../node_modules/@ionic/angular/css/float-elements.css';
@import '../../node_modules/@ionic/angular/css/text-alignment.css';
@import '../../node_modules/@ionic/angular/css/text-transformation.css';
@import '../../node_modules/@ionic/angular/css/flex-utils.css';
*/

@import '@ionic/angular/css/palettes/dark.always.css';

@import '../theme/variables.scss';

@import '../../node_modules/@ionic/angular/css/palettes/dark.always.css';



.as-split-gutter {
  background-color: transparent !important;
  opacity: 0.3;
}



ion-button {

  --border-radius: 25px;
  --padding-top: 13px;
  --padding-bottom: 13px;
  --padding-start: 20px;
  --padding-end: 20px;
  min-height: auto;

  text-transform: uppercase;
  height: 44px;
  --padding-start: 42px;
  --padding-end: 42px;
  --border-color: #222;
  --color: #222;


  &.button-solid {
    --background: var(--um-primary-color);
    --background-hover: hsl(from var(--um-primary-color) h s calc(l - 10));
    --background-activated: var(--background-hover);
  }

  &.button-clear {
    --color: var(--um-primary-color);
    --color-hover: hsl(from var(--um-primary-color) h s calc(l - 10));
    font-size: 14px;

    &:hover {
      opacity: 1;
    }

  }

  &.dark {
    --border-color: white;
    --color: white;
    --background: transparent;
  }

  ion-spinner {
    margin-right: 10px;
    height: 24px;
  }

}

/*
.sc-ion-segment-ios-h.ion-color.sc-ion-segment-ios-s>ion-segment-button {
  --color: #ccc;
  --background: red;
}
*/

beat-maker um-button .mini-button {
  -webkit-appearance: none;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.6);
  padding: 6px 6px;
  margin-right: 2px;
  font-size: 10px;
}

body .tree {
  background-color: #1c2733;

  .p-tree {
    background-color: transparent !important;
    // color: rgba(255, 255, 255, 0.8);
    border: none;

    .p-tree-filter {
      background-color: darken(#1c2733, 2%) !important;
      border-width: 0px;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

.animating-block {
  position: absolute;
  top: 600px;
  left: 200px;
  opacity: 0;
}

.animating-block .bar {
  background-color: #ffd261 !important;
}

.kill-button {
  display: contents;
}

.kill-button .mini-button {
  margin-top: 6px;
  background-color: #262626;
  border-radius: 3px;
  flex-grow: 0;
  padding: 2px 6px;
  align-self: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 9px;
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: inset 1px 1px 3px rgba(255, 255, 255, 0.2);
  border-color: black;
  border-style: solid;
  border-width: 2px;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.8);
  cursor: pointer;

  &:hover {
    background-color: lighten(#262626, 3%);
    color: var(--um-primary-color);
  }

  &.selected {
    background-color: var(--um-primary-color);
    color: #333;
    text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
  }
}

.p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
  color: $um-primary-color;
  border-color: $um-primary-color;
}

.p-highlight .p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
  color: black !important;
  border-color: black !important;
}

.p-menuitem {}

.p-menuitem-text {
  font-size: 0.8em;
}


// track list / playlists
p-table {

  &.app-table-old {
    --p-datatable-row-background: #333;

    --p-datatable-header-cell-background: #111;
    --p-datatable-header-cell-border-color: transparent;
    --p-datatable-header-cell-color: #777;

    --p-datatable-body-cell-border-color: #444;

    --p-datatable-row-selected-background: transparent;
    --p-datatable-row-selected-color: #222;
    --p-datatable-body-cell-selected-border-color: #444;
  }


  &.app-table {
    --p-datatable-row-background: #313131;
    --p-datatable-row-selected-background: var(--um-primary-color);
    --p-datatable-row-hover-background: null;
    --p-datatable-row-striped-background: #2a2a2a;

    --p-datatable-row-color: #eee;
    --p-datatable-row-selected-color: #333;
    --p-datatable-row-hover-color: var(--um-primary-color);

    --p-datatable-header-cell-background: #111;
    --p-datatable-header-cell-border-color: transparent;
    --p-datatable-header-cell-color: #777;
    --p-datatable-header-cell-hover-background: var(--p-datatable-header-cell-background);
    --p-datatable-header-cell-hover-color: var(--p-datatable-header-cell-selected-color);
    --p-datatable-header-cell-selected-background: var(--p-datatable-header-cell-background);
    --p-datatable-header-cell-selected-color: var(--um-primary-color);

    --p-datatable-column-title-font-weight: 700;

    --p-datatable-body-cell-padding: 10px 10px;
    --p-datatable-body-cell-border-color: #111;
    --p-datatable-body-cell-selected-border-color: transparent;

    user-select: none;
    background-color: #333;

    tr {
      &:nth-child(even) {
        //  background-color: rgba(0, 0, 0, 0.2);
      }

      &:focus {
        outline: none;
      }
    }

    // .p-datatable-thead {
    thead {
      border-radius: 10px;
      height: 50px;

      background-color: #222 !important;
      background-color: rgba(0, 0, 0, 0.2) !important;
      background-color: #333;

      th {
        font-size: 16px;
        text-align: left;
        padding: 4px 10px !important;
      }
    }
  }

  .scrollable {
    .p-datatable-flex-scrollable {
      display: inline-grid !important;
    }

    .p-datatable {
      display: inline-grid !important;
    }
  }
}

// FileArchive groups browser
p-tree {

  // Tree Search
  .p-tree-filter-input {
    --p-inputtext-background: rgba(0, 0, 0, 0.2);
    --p-inputtext-border-color: rgba(0, 0, 0, 0.5);
    --p-inputtext-hover-border-color: transparent;
    --p-inputtext-focus-border-color: var(--um-primary-color);
    --p-inputtext-color: var(--um-primary-color);
  }

  p-treenode {
    --p-tree-node-selected-background: rgba(255, 255, 255, 0.1);
    --p-tree-node-selected-color: rgba(255, 255, 255, 0.8);
    --p-tree-node-color: rgba(255, 255, 255, 0.8);
    --p-tree-node-hover-color: rgba(255, 255, 255, 0.8);
    --p-tree-node-hover-background: rgba(255, 255, 255, 0.1);
  }
}




::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

p-rating {
  --p-rating-icon-color: var(--um-primary-color);
  --p-rating-icon-active-color: var(--um-primary-color);
  --p-rating-icon-hover-color: var(--um-primary-color);
}

.p-datatable-row-selected {
  p-rating {
    --p-rating-icon-color: var(--um-primary-opposite-color);
    --p-rating-icon-active-color: var(--um-primary-opposite-color);
    --p-rating-icon-hover-color: var(--um-primary-opposite-color);
  }

}



/* Make the scrollbar dark */
::-webkit-scrollbar {
  width: 14px;
  height: 10px;
}

/* Track (background of the scrollbar) */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 0px;
}

/* Handle (the draggable part) */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.25);
  border-radius: 6px;
  /* Creates a padding effect */
  border: 2px solid #111;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

/* Style the scrollbar corner */
::-webkit-scrollbar-corner {
  background: #000;
  /* Dark color */
}