/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */




@import '../../node_modules/@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */

@import '../../node_modules/@ionic/angular/css/normalize.css';
@import '../../node_modules/@ionic/angular/css/structure.css';
@import '../../node_modules/@ionic/angular/css/typography.css';
@import '../../node_modules/@ionic/angular/css/display.css';


/* Optional CSS utils that can be commented out */
/*
@import '../../node_modules/@ionic/angular/css/padding.css';
@import '../../node_modules/@ionic/angular/css/float-elements.css';
@import '../../node_modules/@ionic/angular/css/text-alignment.css';
@import '../../node_modules/@ionic/angular/css/text-transformation.css';
@import '../../node_modules/@ionic/angular/css/flex-utils.css';
*/

@import '@ionic/angular/css/palettes/dark.always.css';

@import '../theme/variables.scss';

@import '../../node_modules/@ionic/angular/css/palettes/dark.always.css';



.as-split-gutter {
  background-color: transparent !important;
  opacity: 0.3;
}

ion-button {
  --border-radius: 25px;
  --padding-top: 13px;
  --padding-bottom: 13px;
  --padding-start: 20px;
  --padding-end: 20px;
  min-height: auto;
}

/*
.sc-ion-segment-ios-h.ion-color.sc-ion-segment-ios-s>ion-segment-button {
  --color: #ccc;
  --background: red;
}
*/

beat-maker um-button .mini-button {
  -webkit-appearance: none;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.6);
  padding: 6px 6px;
  margin-right: 2px;
  font-size: 10px;
}

body .tree {
  background-color: #1c2733;

  .p-tree {
    background-color: transparent !important;
    color: rgba(255, 255, 255, 0.8);
    border: none;

    .p-tree-filter {
      background-color: darken(#1c2733, 2%) !important;
      border-width: 0px;
      color: rgba(255, 255, 255, 0.8);
    }

    .p-tree-container {
      .p-treenode {
        .p-treenode-content {
          border: none;

          &:focus {
            outline: none;
          }

          .p-treenode-label {
            &.p-highlight {
              // background-color: transparent !important;
              // color: rgba(0, 0, 0, .8);
              // color: var(--um-primary-color);
            }
          }
        }
      }

      .-treenode-content-selected {
        //background-color: var(--um-primary-color);
        // border: none;
      }
    }
  }
}

.animating-block {
  position: absolute;
  top: 600px;
  left: 200px;
  opacity: 0;
}

.animating-block .bar {
  background-color: #ffd261 !important;
}

.kill-button {
  display: contents;
}

.kill-button .mini-button {
  margin-top: 6px;
  background-color: #262626;
  border-radius: 3px;
  flex-grow: 0;
  padding: 2px 6px;
  align-self: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 9px;
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: inset 1px 1px 3px rgba(255, 255, 255, 0.2);
  border-color: black;
  border-style: solid;
  border-width: 2px;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.8);
  cursor: pointer;

  &:hover {
    background-color: lighten(#262626, 3%);
    color: var(--um-primary-color);
  }

  &.selected {
    background-color: var(--um-primary-color);
    color: #333;
    text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
  }
}

/* PrimeNG table */
.p-datatable-table {
  user-select: none;
  background-color: #333;
  // flex-grow: 1;

  ::ng-deep thead {
    background-color: #222 !important;
    background-color: rgba(0, 0, 0, 0.2) !important;
  }

  .p-datatable-thead {
    background-color: #333;
  }

  .p-datatable-tbody {

    .rating.color-positive .star-container .star svg {
      fill: var(--um-primary-color) !important;
    }

    td {
      padding: 4px 10px !important;
    }
  }

  th {
    background-color: transparent;
    border-width: 0px 1px;
    border-color: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.3);
    text-align: left;
    padding: 4px 10px !important;
  }

  tr {
    background-color: rgba(0, 0, 0, 0.3);

    &:focus {
      outline: none;
    }

    &:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.2);
    }

    td {
      border-width: 0 1px;
      border-color: rgba(255, 255, 255, 0.05);
      color: #eee;

      .cover {
        width: 32px;
      }
    }

    ion-button {
      min-width: 40px;
    }

    &:not(.p-highlight):hover {
      background-color: var(--um-primary-color) !important;
      background-color: rgba(255, 255, 255, 0.01) !important;
    }

    &.p-highlight {
      background-color: var(--um-primary-color);

      td {
        color: #111 !important;
      }
    }
  }
}

.p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
  color: $um-primary-color;
  border-color: $um-primary-color;
}

.p-highlight .p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
  color: black !important;
  border-color: black !important;
}

.p-menuitem {}

.p-menuitem-text {
  font-size: 0.8em;
}

p-table.scrollable {
  .p-datatable-flex-scrollable {
    display: inline-grid !important;
  }

  .p-datatable {
    display: inline-grid !important;
  }
}

p-table {
  --p-datatable-row-background: #333;
  --p-datatable-body-cell-border-color: #444;
  --p-datatable-header-cell-background: #111;
  --p-datatable-header-cell-border-color: transparent;
  --p-datatable-header-cell-color: #777;
  //--p-datatable-row-selected-background: var(--um-primary-color);  
  --p-datatable-row-selected-background: transparent;
  --p-datatable-row-selected-color: #222;
  --p-datatable-body-cell-selected-border-color: #444;


  .p-datatable-thead {
    height: 50px;
  }
}

p-treenode {
  --p-tree-node-selected-background: rgba(255, 255, 255, 0.1);
  --p-tree-node-selected-color: rgba(255, 255, 255, 0.8);
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

ion-button {
  text-transform: uppercase;
  height: 44px;
  --padding-start: 42px;
  --padding-end: 42px;
  --border-color: #222;
  --color: #222;


  &.button-solid {
    --background: var(--um-primary-color);
    --background-hover: hsl(from var(--um-primary-color) h s calc(l - 10));
    --background-activated: var(--background-hover);
  }

  &.button-clear {
    --color: var(--um-primary-color);
    --color-hover: hsl(from var(--um-primary-color) h s calc(l - 10));
    font-size: 14px;

    &:hover {
      opacity: 1;
    }

  }

  &.dark {
    --border-color: white;
    --color: white;
    --background: transparent;
  }

  ion-spinner {
    margin-right: 10px;
    height: 24px;
  }

}